<template>
  <form class="m-page-component--inpage" @submit.prevent>
    <div class="g g--no-gutters align-v-center">
      <div class="g__col">
        <h3>Refine results</h3>
      </div>
      <div class="g__col align-right u-text-right">
        <reset-all-filters class="link" active-class="link--primary"  />
      </div>
    </div>
    <div class="island island--grey island--bordered-full p--double">
      <year-of-entry />
      <div
        v-for="(facet, index) in filtersForUi.all.filter(facet => shouldShowFacet(facet))"
        :key="facet.name" >
        <component :is="facet.type" :facet="facet" />
        <hr v-if="index < filtersForUi.all.length - 1" class="hr hr--dark hr--xsmall" />
      </div>
    </div>
  </form>
</template>
<script>
import FiltersBase from "@/js/search/components/filters/FiltersBase";

export default {
  extends: FiltersBase,
};
</script>

<style lang="scss">
  .link{
    &:focus-visible {
      outline: none !important;
      border: 2px solid #ba0046;
    }
  }
</style>
