<template>
  <div class="wrapper">
    <template v-if="solrAvailable">
      <search-box />
      <search-title />
      <div id="searchContainer" class="g">
        <div
          v-if="showFilters"
          :class="{ 'g__col-4@large': hasSidebar }"
          class="g__col g__col-12 mg-bottom"
        >
        <a class="sr-only focusable skiplink" href="#results" tabindex="0">Skip to results</a>
          <filter-button-mobile v-if="isMobile && hasSidebar" />
          <filters-tablet v-else-if="isTablet || !hasSidebar" />
          <filters-desktop v-else />

          <div v-if="isMobile" class="g">
            <div class="g__col align-right p-top--half">
              <reset-all-filters class="link" active-class="link--primary" />
            </div>
          </div>

          <div
            v-if="isTablet || isMobile"
            class="g__col g__col-12 p-top--triple p-top@medium p-bottom--triple p-bottom--double@medium"
          >
            <div class="g align-center align-right@medium">
              <pager class="g__col g__col-12 align-center align-right@medium" />
            </div>
          </div>
        </div>

        <div id="results" v-if="showResults" :class="{ 'g__col-8@large': hasSidebar }" class="g__col g__col-12">
          <div class="p-bottom--double">
            <search-results />
            <div class="g">
              <div
                class="g__col g__col-12 g__col-auto@medium align-left@medium g__col-order-2 g__col-order-1@medium"
              >
                <div v-if="isMobile" class="g align-center p-top--double">
                  <per-page class="g__col" />
                </div>
                <div v-else class="g">
                  <per-page class="g__col" />
                </div>
              </div>
              <div
                class="g__col g__col-12 g__col-6@medium align-center align-right@medium u-text-right g__col-order-1 g__col-order-2@medium"
              >
                <div id="bottomPager" class="g align-center align-left@medium">
                  <pager class="g__col align-center align-right@medium" />
                </div>
              </div>
            </div>
            <hr class="hr--small" />
          </div>
        </div>
      </div>
      <filters-modal v-if="showMobileFilters" />
    </template>
    <div v-else-if="hasSolrClient">
      <fallback-search
        message="Sorry, it looks like we're having trouble loading instant course search results at the moment, but you can still search all courses using the form below."
      />
    </div>
    <div v-else>
      <p><strong>Loading course search&hellip;</strong></p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { scrollToTarget } from "@/js/helpers/scroll-to-helper";

import BaseSearchWrapper from "@/js/search/components/base/BaseSearchWrapper";
import FallbackSearch from "@/js/search/components/query/FallbackSearch";
import FilterButtonMobile from "@/js/search/components/filters/FilterButtonMobile";
import FiltersDesktop from "@/js/search/components/filters/FiltersDesktop";
import FiltersModal from "@/js/search/components/filters/FiltersModal";
import FiltersTablet from "@/js/search/components/filters/FiltersTablet";
import Pager from "@/js/search/components/pagination/Pager";
import PerPage from "@/js/search/components/results/PerPage";
import ResetAllFilters from "@/js/search/components/filters/ResetAllFilters";
import SearchBox from "@/js/search/components/query/SearchBox";
import SearchResults from "@/js/search/components/results/SearchResults";
import SearchTitle from "@/js/search/components/SearchTitle";

export default {
  components: {
    FallbackSearch,
    FilterButtonMobile,
    FiltersDesktop,
    FiltersModal,
    FiltersTablet,
    Pager,
    PerPage,
    ResetAllFilters,
    SearchBox,
    SearchResults,
    SearchTitle,
  },
  extends: BaseSearchWrapper,
  computed: {
    ...mapGetters(["page", "showMobileFilters", "totalPages", "hasSidebar", "showFilters"]),
  },
  watch: {
    totalPages() {
      this.scrollToContainer();
    },
    page() {
      this.scrollToContainer();
    },
  },
  methods: {
    scrollToContainer() {
      this.$nextTick(() => scrollToTarget(this.$el, {}));
    },
  },
};
</script>
