<template>
  <div class="select__wrapper">
    <select :id="id" :name="name" class="select" :class="className" @change="onChange">
      <option
        v-for="option in options"
        :key="option.value"
        :selected="isSelected(option)"
        :value="option.value"
        >{{ option.label }}</option
      >
    </select>

    <span class="select__icon"></span>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: [Object, null],
      default: null,
    },
    id: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
  },
  methods: {
    isSelected(option) {
      return option.value === this.selected.value;
    },
    onChange({ target }) {
      this.$emit("change", target.value);
    },
  },
};
</script>

<style lang="scss">
  .select{
    &:focus-visible{
      border: 2px solid #ba0046 !important;
    }
  }
</style>